import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMediaQuery, useTheme } from "@mui/material";
import SVGPlus from "@/assets/icons/SVGPlus.tsx";
import Modal from "@/components/Modal";
import ProjectForm from "@/pages/ProjectDetailsPage/components/ProjectForm.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import { useUserStore } from "@/store/userStore.ts";
import { createProjectReq } from "@/api/projectsApi";
import { PROJECT_ALREADY_EXISTS_ERROR_MESSAGE } from "@/constants";
import { StyledRightActionButton } from "@/components/styled";
import { validateCustomerName } from "@/utils/validateCustomerName.ts";

export type AddProjectForm = {
  customerName: string;
  address: string;
  upfrontIncentivePayment: string;
  contractor: string;
  batteryOem: string;
  batteryModel: string;
  usableCapacity: string;
};

const AddProjectModal = ({
  getProjectsReq,
}: {
  getProjectsReq: () => void;
}) => {
  const [step, setStep] = useState<1 | 2>(1);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const role = useAuthStore((state) => state.role);
  const user = useUserStore((state) => state.user);
  const methods = useForm<AddProjectForm>({
    mode: "onChange",
  });

  const { handleSubmit, reset, setValue, setError } = methods;

  const handleClose = () => {
    setOpen(false);
    reset();
    setStep(1);
  };

  const onSubmit = async (data: AddProjectForm) => {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        const value = data[key as keyof Partial<AddProjectForm>];
        if (value !== undefined) {
          if (key === "upfrontIncentivePayment") {
            formData.append(
              key,
              `${Number(data?.upfrontIncentivePayment) * 100}`,
            );
          } else {
            formData.append(key, value);
          }
        }
      });
      await createProjectReq(formData);
      getProjectsReq();
      handleClose();
    } catch (e: any) {
      if (e?.response?.data?.error === PROJECT_ALREADY_EXISTS_ERROR_MESSAGE) {
        setError("customerName", { message: e?.response?.data?.error });
      } else {
        console.error(e);
      }
    }
  };

  const handleStep = async (step: 1 | 2) => {
    if (step === 1) {
      setStep(step);
    }
    if (step === 2) {
      const customerName = methods.getValues("customerName");
      const customerNameError = await validateCustomerName(customerName ?? "");
      if (customerNameError === true) {
        setStep(step);
      } else {
        methods.setError("customerName", { message: customerNameError });
      }
    }
  };

  useEffect(() => {
    if (role === "contractor" && user?.companyName) {
      setValue("contractor", user?.companyName);
    }

    if (role === "oem" && user?.manufactoringBrand) {
      setValue("batteryOem", user?.manufactoringBrand);
    }
  }, [role, user?.companyName, setValue, open, user?.manufactoringBrand]);

  return (
    <>
      <StyledRightActionButton
        sx={{
          gap: "6px",
          whiteSpace: "nowrap",
          ...(!downSm && { minWidth: "180px" }),
        }}
        onClick={() => setOpen(true)}
        variant={"contained"}
      >
        <SVGPlus />
        {!downSm && "Add Project"}
      </StyledRightActionButton>
      <Modal
        open={open}
        onClose={handleClose}
        title={"Add Project"}
        subtitle={`${step}/2 steps`}
      >
        <FormProvider {...methods}>
          <ProjectForm
            handleSubmit={handleSubmit(onSubmit)}
            handleClose={handleClose}
            handleStep={handleStep}
            step={step}
          />
        </FormProvider>
      </Modal>
    </>
  );
};

export default AddProjectModal;
