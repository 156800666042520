import React, { useEffect } from "react";
import { useAuthStore } from "@/store/authStore.ts";
import { useFormContext } from "react-hook-form";
import AsyncSelectWithSearch from "@/components/AsyncSelectWithSearch";
import { getBatteryOemsReq, getContractorsReq } from "@/api/userApi";
import { getBatteryCapacityReq, getBatteryModelsReq } from "@/api/batteryApi";
import FormInput from "@/components/FormInput";
import { Box } from "@mui/material";
import FormFileUpload from "@/components/FormUploadInput";
import { FileRecord } from "@/types";
import FormRadioList from "@/components/FormRadioList";

type Props = {
  participantEnrollment?: FileRecord;
  contract?: FileRecord;
  systemSizingTool?: FileRecord;
};

const SecondProjectForm = ({
  participantEnrollment,
  contract,
  systemSizingTool,
}: Props) => {
  const role = useAuthStore((state) => state.role);
  const { control, getValues, watch, setValue } = useFormContext();

  const batteryModelId = watch("batteryModelId");
  const participantEnrollmentFormValue = watch("participantEnrollment");
  const contractFormValue = watch("contract");
  const systemSizingToolFormValue = watch("systemSizingTool");

  const getFileInfoObject = (file: File | FileRecord | undefined) => {
    return file
      ? {
          fileName:
            ("name" in file && file?.name) ||
            ("fileName" in file && file?.fileName) ||
            "",
          size: `${file?.size}`,
        }
      : undefined;
  };
  useEffect(() => {
    if (batteryModelId) {
      const fetchBatteryCapacity = async () => {
        try {
          const response = await getBatteryCapacityReq(batteryModelId);
          setValue("usableCapacity", response?.data?.batteryCapacity || "0");
        } catch (e) {
          console.error(e);
        }
      };
      fetchBatteryCapacity();
    }
  }, [batteryModelId]);

  return (
    <>
      <AsyncSelectWithSearch
        name={"contractor"}
        idKey={"contractorId"}
        disabled={role === "contractor"}
        control={control}
        label={"Contractor"}
        getItems={(searchValue) => getContractorsReq(searchValue)}
        itemsKey={"contractors"}
        placeholder={"Select contractor"}
        controlProps={{ rules: { required: "Required" } }}
      />
      <AsyncSelectWithSearch
        name={"batteryOem"}
        idKey={"oemId"}
        control={control}
        disabled={role === "oem"}
        label={"Battery Manufacturer"}
        getItems={(searchValue) => getBatteryOemsReq(searchValue)}
        itemsKey={"oems"}
        placeholder={"Select battery OEM"}
      />
      <AsyncSelectWithSearch
        name={"batteryModel"}
        idKey={"batteryModelId"}
        control={control}
        label={"Battery product"}
        disabled={!getValues("batteryOem")}
        getItems={(searchValue) =>
          getBatteryModelsReq(getValues("oemId"), searchValue)
        }
        itemsKey={"batteryModels"}
        placeholder={"Select battery model"}
      />
      <FormInput
        name={"usableCapacity"}
        disabled={true}
        control={control}
        label={"Nameplate Usable Capacity (kWh)"}
        placeholder={"To see nameplate capacity select a battery product"}
      />
      <FormInput
        name={"numberOfBatteries"}
        control={control}
        label={"Number of batteries"}
        placeholder={"To see nameplate capacity select a battery product"}
      />
      <Box sx={{ display: "flex", gap: "16px" }}>
        <FormInput
          name={"summerEnrolment"}
          control={control}
          label={"Summer Enrollment %"}
          placeholder={"000"}
          controlProps={{ rules: { required: "Required" } }}
        />
        <FormInput
          name={"winterEnrolment"}
          control={control}
          label={"Winter Enrollment %"}
          placeholder={"000"}
          controlProps={{
            rules: {
              required: "Required",
            },
          }}
        />
      </Box>
      <FormRadioList
        name={`participation`}
        control={control}
        label={"Participation"}
        items={[
          { value: "weekdays", label: "Weekdays Only" },
          { value: "weekdays_weekends", label: "Weekdays & Weekends" },
        ]}
        controlProps={{ rules: { required: "Required" } }}
      />
      <FormFileUpload
        name="participantEnrollment"
        control={control}
        label="Participant Enrollment"
        currentFileInfo={
          getFileInfoObject(participantEnrollmentFormValue) ||
          participantEnrollment
        }
        controlProps={{ rules: { required: "Required" } }}
      />
      <FormFileUpload
        name="systemSizingTool"
        control={control}
        label="System Sizing Tool"
        currentFileInfo={
          getFileInfoObject(systemSizingToolFormValue) || systemSizingTool
        }
        controlProps={{ rules: { required: "Required" } }}
      />
      <FormFileUpload
        name="contract"
        control={control}
        label="Contract"
        currentFileInfo={getFileInfoObject(contractFormValue) || contract}
      />
    </>
  );
};

export default SecondProjectForm;
