import React from "react";
import FormInput from "@/components/FormInput";
import { INCENTIVE_STATUS } from "@/constants";
import { useFormContext } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import FormCurrencyInput from "@/components/FormCurrencyInput";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import { useAuthStore } from "@/store/authStore.ts";
import { ProjectRecord } from "@/types";

const FirstProjectForm = ({
  incentiveStatus,
  enrollmentStatus,
}: {
  incentiveStatus?: INCENTIVE_STATUS | undefined;
  enrollmentStatus?: ProjectRecord["enrolmentStatus"] | undefined;
}) => {
  const { watch, control } = useFormContext();
  const upfrontIncentivePayment = Number(watch("upfrontIncentivePayment"));
  const role = useAuthStore((state) => state.role);
  const isAdmin = role === "administrator";

  const isUpfrontIncentivePaymentDisabled =
    !isAdmin &&
    ((!!incentiveStatus && incentiveStatus !== INCENTIVE_STATUS.PENDING) ||
      (!!enrollmentStatus && enrollmentStatus !== "pending"));

  const isNameAndAddressDisabled =
    !isAdmin && incentiveStatus === INCENTIVE_STATUS.RESERVED;

  return (
    <>
      <FormInput
        name={"customerName"}
        control={control}
        label={"Customer name"}
        placeholder={"Add customer name"}
        disabled={isNameAndAddressDisabled}
        controlProps={{ rules: { required: "Required" } }}
      />
      <FormInput
        name={"address"}
        control={control}
        label={"Address"}
        placeholder={"Add address"}
        disabled={isNameAndAddressDisabled}
        controlProps={{ rules: { required: "Required" } }}
      />
      <Box sx={{ display: "flex", gap: "16px" }}>
        <FormInput
          name={"city"}
          control={control}
          label={"City"}
          placeholder={"Add city"}
          controlProps={{ rules: { required: "Required" } }}
        />
        <FormInput
          name={"zipCode"}
          control={control}
          label={"Zip code"}
          placeholder={"Add zip code"}
          controlProps={{
            rules: {
              required: "Required",
            },
          }}
        />
      </Box>
      <FormInput
        name={"accountHash"}
        control={control}
        label={"Account #"}
        placeholder={"000000000000"}
        controlProps={{
          rules: {
            required: "Required",
            validate: (value) => {
              if (value?.length < 12) {
                return "Account # should be a minimum of 12 digits.";
              }
              if (value?.length > 13) {
                return "Invalid value";
              }
            },
          },
        }}
      />
      <FormInput
        name={"accountEmail"}
        control={control}
        label={"Customer email"}
        placeholder={"Add customer email"}
        controlProps={{
          rules: {
            required: "Required",
          },
        }}
      />
      <Typography
        sx={{ padding: "0 0 28px 0", display: "flex" }}
        variant={"bodyRegular"}
      >
        Existing Solar:
        <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
      </Typography>
      <FormInput
        name={"existingInverter"}
        control={control}
        label={"Existing Inverter"}
        placeholder={"Add existing inverter"}
        controlProps={{
          rules: {
            required: "Required",
          },
        }}
      />
      <Grid container columnSpacing={"8px"}>
        <Grid item xs={6} lg={4}>
          <FormInput
            type={"number"}
            name={"solarKwdc"}
            control={control}
            label={"kW-DC"}
            placeholder={"100"}
            controlProps={{
              rules: {
                required: "Required",
              },
            }}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormInput
            type={"number"}
            name={"solarKwac"}
            control={control}
            label={"kW-AC"}
            placeholder={"100"}
            controlProps={{
              rules: {
                required: "Required",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormInput
            type={"number"}
            name={"solarAnnualProduction"}
            control={control}
            label={"Annual Production"}
            placeholder={"100"}
            controlProps={{
              rules: {
                required: "Required",
              },
            }}
          />
        </Grid>
      </Grid>
      <Typography
        sx={{ padding: "0 0 28px 0", display: "flex" }}
        variant={"bodyRegular"}
      >
        New Solar:
        <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
      </Typography>
      <Grid container columnSpacing={"8px"}>
        <Grid item xs={6} lg={4}>
          <FormInput
            type={"number"}
            name={"newSolarKwdc"}
            control={control}
            label={"kW-DC"}
            placeholder={"100"}
            controlProps={{
              rules: {
                required: "Required",
              },
            }}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormInput
            type={"number"}
            name={"newSolarKwac"}
            control={control}
            label={"kW-AC"}
            placeholder={"100"}
            controlProps={{
              rules: {
                required: "Required",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormInput
            type={"number"}
            name={"newSolarAnnualProduction"}
            control={control}
            label={"Annual Production"}
            placeholder={"100"}
            controlProps={{
              rules: {
                required: "Required",
              },
            }}
          />
        </Grid>
      </Grid>
      <FormCurrencyInput
        name={"upfrontIncentivePayment"}
        control={control}
        label={"Upfront Incentive Payment"}
        placeholder={"$00.00"}
        maxValue={10000}
        defaultValue={
          upfrontIncentivePayment > 0
            ? formatCurrency(upfrontIncentivePayment / 100)
            : ""
        }
        controlProps={{ rules: { required: "Required" } }}
        disabled={isUpfrontIncentivePaymentDisabled}
      />
    </>
  );
};

export default FirstProjectForm;
